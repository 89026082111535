<template>
  <div class="login">
    <a-card class="box-card">
      <h1>登录</h1>
      <a-form class="login-form" :form="form" @submit="handleSubmit">
        <a-form-item>
          <a-input
            allowClear
            placeholder="邮箱地址"
            v-decorator="[
              'email',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入邮箱地址',
                  },
                  {
                    type: 'email',
                    message: '邮箱格式有误',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="mail" style="color:rgba(0,0,0,.25)" />
          </a-input>
        </a-form-item>

        <a-form-item>
          <a-input-password
            allowClear
            placeholder="密码"
            v-decorator="[
              'password',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入密码',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)" />
          </a-input-password>
        </a-form-item>

        <a-form-item style="margin: 0">
          <a-button type="primary" html-type="submit" size="large" @click="handleSubmit">
            登录
          </a-button>

          <p class="desc">还没有账号 ？ <router-link to="/user/register">注册</router-link></p>
        </a-form-item>
      </a-form>
    </a-card>
  </div>
</template>

<script>
import { Encrypt, ENV, Storage } from '@/utils';
export default {
  name: 'login',
  data() {
    return {
      form: this.$form.createForm(this, { name: 'loginForm' }),
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          values.loginType = 'email';
          values.password = Encrypt(values.email, values.password);
          const res = await this.$store.dispatch('login', values);
          if (res.code === 0) {
            Storage.set(ENV.storage.token, res.data.token); //保存token
            this.$store.commit('changeLoginStatus', {
              auth: true,
              userInfo: {
                detail: res.data.detail,
                token: res.data.token,
              },
            });
            const { query } = this.$route;
            if (query && query.redirect) {
              this.$router.push(query.redirect);
            } else {
              this.$router.push('/projects');
            }
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
.login {
  .box-card {
    width: 400px;
    margin: auto;
  }
  h1 {
    font-size: 18px;
    font-weight: 400;
  }
  button {
    width: 100%;
  }
  .desc {
    margin-top: 20px;
  }
  .ant-form-explain {
    text-align: left;
  }
}
</style>
