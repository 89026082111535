var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login"},[_c('a-card',{staticClass:"box-card"},[_c('h1',[_vm._v("登录")]),_c('a-form',{staticClass:"login-form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            {
              rules: [
                {
                  required: true,
                  message: '请输入邮箱地址',
                },
                {
                  type: 'email',
                  message: '邮箱格式有误',
                } ],
            } ]),expression:"[\n            'email',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入邮箱地址',\n                },\n                {\n                  type: 'email',\n                  message: '邮箱格式有误',\n                },\n              ],\n            },\n          ]"}],attrs:{"allowClear":"","placeholder":"邮箱地址"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"mail"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                {
                  required: true,
                  message: '请输入密码',
                } ],
            } ]),expression:"[\n            'password',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入密码',\n                },\n              ],\n            },\n          ]"}],attrs:{"allowClear":"","placeholder":"密码"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',{staticStyle:{"margin":"0"}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","size":"large"},on:{"click":_vm.handleSubmit}},[_vm._v(" 登录 ")]),_c('p',{staticClass:"desc"},[_vm._v("还没有账号 ？ "),_c('router-link',{attrs:{"to":"/user/register"}},[_vm._v("注册")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }